
.currency-inr{
    &::before{
        content: "\20B9";   
    }
}

.currency-usd{
    &::before{
        content: "\24";   
    }
}

.currency-eur{
    &::before{
        content: "\20AC";   
    }
}

.currency-gbp{
    &::before{
        content: "\A3";   
    }
}

.currency-bdt{
    &::before{
        content: "\09F3";   
    }
}

.currency-php{
    &::before{
        content: "\20B1";   
    }
}

.currency-thb{
    &::before{
        content: "\0E3F";   
    }
}

.currency-aed{
    &::before{
        content: "AED";   
    }
}

.currency-aud{
    &::before{
        content: "AUD";   
    }
}

.currency-bhd{
    &::before{
        content: "BHD";   
    }
}

.currency-btn{
    &::before{
        content: "BTN";   
    }
}

.currency-brl{
    &::before{
        content: "BRL";   
    }
}

.currency-cad{
    &::before{
        content: "CAD";   
    }
}

.currency-fjd{
    &::before{
        content: "FJD";   
    }
}

.currency-khr{
    &::before{
        content: "KHR";   
    }
}

.currency-zar{
    &::before{
        content: "ZAR";   
    }
}

.currency-kwd{
    &::before{
        content: "KWD";   
    }
}

.currency-lkr{
    &::before{
        content: "LKR";   
    }
}

.currency-ngn{
    &::before{
        content: "NGN";   
    }
}

.currency-npr{
    &::before{
        content: "NPR";   
    }
}

.currency-nzd{
    &::before{
        content: "NZD";   
    }
}

.currency-omr{
    &::before{
        content: "OMR";   
    }
}

.currency-qar{
    &::before{
        content: "QAR";   
    }
}

.currency-sar{
    &::before{
        content: "SAR";   
    }
}

.currency-sgd{
    &::before{
        content: "SGD";   
    }
}

.currency-myr{
    &::before{
        content: "MYR";   
    }
}

.currency-kes{
    &::before{
        content: "KES";   
    }
}

.currency-jod{
    &::before{
        content: "JOD";   
    }
}

.currency-vnd{
    &::before{
        content: "VND";   
    }
}